<template>

  <b-card no-body>
    <div class="m-1">
      <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <v-select
              v-model="perPage"
              class="per-page-selector d-inline-block mx-50"
              :options="perPageOptions"
              :clearable="false"/>
          <span class="mr-1">Category:</span>
          <v-select
              v-model="categoryId"
              :options="categoryOptions"
              :reduce="val => val.value"
              :clearable="true"
              class="category-select"/>
        </b-col>
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."/>
            <header-table :new-route="{ name: 'cms-content-add' }" :re-fetch-data="reFetchData"></header-table>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
        ref="refListTable"
        primary-key="id"
        empty-text="No record found"
        :class="`position-relative table-sticky ${totalCount > 0 && totalCount < 5 ? 'dropdown-h150': ''}`"
        :items="fetchList"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive striped bordered hover show-empty>

      <template #cell(id)="data">
        <id-column-table :data="data.value" :route-name="'cms-content-edit'"></id-column-table>
      </template>

      <template #cell(status)="data">
        <active-column-table :data="data.value"></active-column-table>
      </template>

      <template #cell(actions)="data">
        <div class="text-nowrap">
          <images-button-table :data="data" :router-name="'cms-content-images'"></images-button-table>
          <edit-button-table :data="data" :router-name="'cms-content-edit'"></edit-button-table>
          <remove-button-table :data="data" :re-fetch-data="reFetchData"></remove-button-table>
        </div>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <footer-table :data-meta="dataMeta"></footer-table>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number last-number>

            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>

            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>

          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <Overlay :busy="busy"></Overlay>

  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay,
} from 'bootstrap-vue'
import {
  FooterTable, RemoveButtonTable, EditButtonTable, DateColumnTable,
  ActiveColumnTable, BooleanColumnTable, IdColumnTable, HeaderTable
} from '@/components/Table';

import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from '@/store'
import useList from './useList'
import storeModule from './store'
import categoryModule from "@/views/cms/category/store"
import Overlay from "@/components/Overlay.vue";
import {avatarText, getApiFile} from "@core/utils/filter";
import ImagesButtonTable from "@/components/Table/ImagesButtonTable.vue";
import {toastMessage} from "@core/utils/utils";

export default {
  components: {
    ImagesButtonTable,
    HeaderTable, FooterTable, RemoveButtonTable, EditButtonTable,
    DateColumnTable, ActiveColumnTable, BooleanColumnTable, IdColumnTable,

    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem,
    BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay,

    vSelect,
    Overlay,
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_CATEGORY_MODULE_NAME = 'store-category'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_CATEGORY_MODULE_NAME, categoryModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_CATEGORY_MODULE_NAME)
      }
    })

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      categoryId,

      fetchList,
      reFetchData,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
    } = useList()

    const categoryOptions = ref([])
    busy.value = true
    store.dispatch('store-category/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.status === 1) {
          categoryOptions.value.push({label: value.title, value: value.id})
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    return {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      categoryId,
      categoryOptions,
      resolveUserRoleVariant,
      resolveUserRoleIcon,

      fetchList,
      reFetchData,
      avatarText,
      getApiFile,
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.category-select {
  min-width: 30%;
}
</style>
